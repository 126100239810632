import React from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect, useState } from "react";


// Components
import CardList from "../components/CardList";
import Footer from "../components/Footer";
import Parallax from "../components/Parallax";
import CardListJoin from '../components/CardListJoin'
// Images
import boat from "../assets/images/Group_343.jpg";

// Styles
import styles from "./Join.module.scss";
import "../App-join.scss";

function Join() {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

 /* var jobPostingData = [
    
	
	  {
      id: 2,
      title: "Service Stewardess | 100m+",
      description:
       "We are looking for a Service Stewardess to join a 100m+ private motor yacht. ",
	   experience : "One season on yachts in a service based role or strong shoreside hospitality background in high end hotels/restaurants preferred. ",
	  certification : "Valid STCW and ENG1 Medical as a minimum.",
      currency: "Package",
      salary: " 122 Days Leave (4:2 Rotation), Salary paid in EUR, Flights and 24/7 Medical Cover. ",
      startDate: "At the Earliest. ",
    },
	 {
      id: 3,
      title: "Housekeeper | 100m+",
      description:
       "We are looking for a Housekeeper to join a 100m+ private motor yacht.",
	   experience : "One season on yachts with Housekeeping duties or relevant shoreside experience.",
	  certification : "Valid STCW and ENG1 Medical as a minimum.",
      currency: "Package",
      salary: " 122 Days Leave (4:2 Rotation), Salary paid in EUR, Flights and 24/7 Medical Cover. ",
      startDate: "At the Earliest. ",
    },
	 {
      id: 4,
      title: "Deckhand | 60m+",
      description:
       "We are looking for a Deckhand to join a 60m+ private motor yacht.",
	   experience : "One season on yachts as Deckhand.",
	  certification : "Valid STCW and ENG1 Medical as a minimum.",
      currency: "Package",
      salary: " 122 Days Leave (4:2 Rotation), Salary paid in EUR, Flights and 24/7 Medical Cover. ",
      startDate: "At the Earliest. ",
    },
	
	{
      id: 5,
      title: "Motorman | 100m+",
      description:
       "We are looking for a Motorman to join a 100m+ private motor yacht.",
	   experience : "Yacht/cruise ship experience preferred.",
	  certification : "Engine Rating Cert, Valid STCW and ENG1 Medical as a minimum.",
      currency: "Package",
      salary: " 122 Days Leave (4:2 Rotation), Salary paid in EUR, Flights and 24/7 Medical Cover. ",
      startDate: "At the Earliest. ",
    },
	{
      id: 6,
      title: "Housekeeper / Yoga Instructor | 100m+",
      description:
       "We are looking for a Housekeeper/Yoga Instructor to join a 100m+ private motor yacht.",
	   experience : "Experience teaching yoga classes. Yacht/cruise ship experience is beneficial.",
	  certification : "Yoga Teacher Qualification, Valid STCW and ENG1 Medical as a minimum.",
      currency: "Package",
      salary: " 122 Days Leave (4:2 Rotation), Salary paid in EUR, Flights and 24/7 Medical Cover. ",
      startDate: "At the Earliest. ",
    },
    
  ];*/

  const animation = useAnimation();

  useEffect(() => {
    if (inView) {
      animation.start({
        y: 0,
        opacity: 1,
        transition: {
          duration: 0.6,
          delay: 0.1,
        },
      });
    }
    if (!inView) {
      animation.start({
        y: -40,
        opacity: 0,
      });
    }
  }, [inView]);
  

  
  
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [jobPostingData, setItems] = useState([]);
  
  
  
  

  // Note: the empty deps array [] means
  // this useEffect will run once
  // similar to componentDidMount()
  //https://asmyachts.com/api/auth.php
  // https://asmyachts.com/api/apis/job-listing.php
  useEffect(() => {
    fetch("https://asmyachts.com/api/apis/job-listing.php")
      .then(res => res.json())
      .then(
        (result) => {
          //setIsLoaded(true);
		  document.getElementById("wait").style.display = "none";
          setItems(result);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          //setIsLoaded(true);
          setError(error);
        }
      )
  }, [])

  return (
    <>
      {/* <article className={styles.container}>
        <Scroll
          width={1900}
          height={1000}
          contentOffsetY={y}
          dragEnabled={false}
          onScroll={onScroll}
        >
          <img
            height={5000}
            y={backgroundImg}
            className={styles.bg_image}
            src={boat}
          />

          <Frame
            style={{ position: "absolute" }}
            background={""}
            top={200}
            y={titleY}
          >
            <div className={styles.titles_container}>
              <h3 right={300} className={styles.subtitle}>
                ARE YOU:
              </h3>
            </div>
          </Frame>

          <Frame
            style={{ position: "absolute" }}
            background={""}
            top={1200}
            y={titleY}
          >
            <div className={styles.titles_container}>
              <h3 right={300} className={styles.subtitle}>
                ARE dasdas
              </h3>
            </div>
          </Frame>
        </Scroll>
      </article>  */}
      <Parallax />
	  <style>
	  </style>
      <div ref={ref} className={styles.card_section}>
        <motion.h2 animate={animation} className={styles.card_section_title}>
          Current vacancies
        </motion.h2>
		<div id="wait" className={styles.card_section_title}>
				Please wait
		</div>
		
        <div className={styles.card_desktop}>
          <CardList items={jobPostingData} />
        </div>
        <div className={styles.card_mobile}>
          <CardListJoin items={jobPostingData}/>
        </div>
        
      </div>
    </>
  );
}

export default Join;
