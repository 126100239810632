import React from "react";
import ImageSection from "./ImageSection";
import TextSection from "./TextSection";

import image1 from "../assets/images/image-1.jpeg";

function HomeSection() {
  return (
    <div className="home-section" id="subhero">
      <div className="split">
        <div className="image-mobile">
          <ImageSection img={image1} />
        </div>
        <TextSection
          number="01"
          title="Run"
          description="Discover our 360° approach to luxury yacht management"
          link="/run"
        />
        <div className="image-desktop">
          <ImageSection img={image1} />
        </div>
      </div>
    </div>
  );
}

export default HomeSection;
