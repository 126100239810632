import React from "react";
import AddIcon from '@material-ui/icons/Add';

function RunText(props) {
  return (
    <details>
      <summary>
        {props.runTitle}
        <AddIcon fontSize="large" style={{ color: "#999999", height: '53px', width: '104px', marginTop: '18px' }} />
      </summary>
      <div className="drop-content">
        <p>{props.firstLine}</p>
        <p>{props.runText}</p>
      </div>
    </details>
  );
}

export default RunText;
